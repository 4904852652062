<template>
  <div>
    <div class="ll-box">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Configuration') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Internal Status Code') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- Add Btn -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="info"
          :to="{ name: 'create-status' }"
          v-permission="[180]"
        >
          <span class="text-nowrap">{{ $t('Create') }}</span>
        </b-button>
      </div>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-input-group>
              <b-input-group-prepend
                is-text
                v-b-toggle.filterS
                class="ll-outline">
                 <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-form-input
                v-model="statusNo"
                class="searchInput"
                placeholder="Please Enter Status No"
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                @click="searchById"
                >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title="" right shadow backdrop bg-variant="white">
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item :title="$t('Status Type')">
            <v-select
              id="statusType"
              :options="statusTypeList"
              label="statusType"
              @input="selStatusType"
              placeholder=""
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Is Active')">
            <v-select
              id="isActive"
              :options="statusList"
              label="status"
              @input="selStatus"
              placeholder=""
            ></v-select>
          </app-collapse-item>
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            @click="getList"
            v-b-toggle.filterS>
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <!-- table -->
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-permission="[179]"
      :sticky-header="true"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(statusType)="row">
        <!-- {{ row.value.toLowerCase().replace('_', ' ') }} -->
        {{ row.value }}
      </template>
      <template #cell(statusDesc)="row">
        {{ row.value }}
      </template>
      <template #cell(intCode)="row">
        {{ row.value }}
      </template>
      <template #cell(tag)="row">
        <div class="ll-tagBox">
          <b-badge
            pill
            class="ll-badge"
            v-if="row.value === 'CUS_COMPLETE' || row.value === 'DELIVERED' || row.value === 'SELF_PICKUP'"
            :variant="`light-${color0}`">
            <span v-if="row.value !== null">{{ row.value.replace('_', ' ') }}</span>
          </b-badge>
          <b-badge
            pill
            class="ll-badge"
            v-if="row.value === 'NO_SORT_CODE' || row.value === 'RESCHEDULED' || row.value === 'DELIVERY_DELAY' || row.value === 'LATE_ARRIVAL'"
            :variant="`light-${color1}`">
            <span v-if="row.value !== null">{{ row.value.replace(/_/g, ' ') }}</span>
          </b-badge>
          <b-badge
            pill
            class="ll-badge"
            v-if="row.value === 'REJECTED' || row.value === 'DAMAGED' || row.value === 'UNDELIVERABLE' || row.value === 'LOST' || row.value === 'DAMAGED' || row.value === 'SCRAPPED'"
            :variant="`light-${color2}`">
            <span v-if="row.value !== null">{{ row.value.replace('_', ' ') }}</span>
          </b-badge>
          <b-badge
            pill
            class="ll-badge"
            v-if="row.value === 'REMOTE_AREA' || row.value === 'RETURNED'"
            :variant="`light-${color3}`">
            <span v-if="row.value !== null">{{ row.value.replace('_', ' ') }}</span>
          </b-badge>
        </div>
      </template>
      <template #cell(isEnabled)="row">
        <b-badge
          :variant="row.value ? 'light-info' : 'light-secondary'"
          class="ll-badge">
          <span>{{ row.value ? 'Active' : 'Inactive' }}</span>
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <b-button
          variant="flat-secondary"
          class="btn-icon rounded-circle ll-act"
          v-b-toggle.modifyS
          v-if="false"
        >
          <feather-icon icon="EditIcon" @click="onEdit(data.item.id, data.item.statusNo)"/>
          <!-- <b-tooltip
            title="Edit"
            :target="`${data.item.id}`"
          /> -->
        </b-button>
        <feather-icon
          icon="EditIcon"
          @click="$router.push({ name: 'status-detail', params: { id: data.item.statusNo }})"
          v-permission="[181]"
        />
      </template>
    </b-table>
    <!-- show pages -->
    <div class="d-flex justify-content-between flex-wrap ll-p">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <!-- Edit sidebar -->
    <b-sidebar id="modifyS" title="Edit Status Code" right shadow backdrop>
      <div class="px-3">
        <b-form-group
          class="g-label"
          label="Status Type"
          label-for="sType"
        >
          <b-form-input
            id="sType"
            v-model="editInfo.statusType"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Status No"
          label-for="statusNo"
        >
          <b-form-input
            id="statusNo"
            v-model="editInfo.statusNo"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Status Description"
          label-for="statusDes"
        >
          <b-form-input
            id="statusDes"
            v-model="editInfo.statusDesc"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Tag"
          label-for="tag"
        >
          <b-form-input
            id="tag"
            v-model="editInfo.tag"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Tag Color"
          v-if="false"
        >
          <colorPicker v-model="color" @change="headleChangeColor"/>
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Is Active"
        >
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="editInfo.isEnabled"
              name="some-radios"
              value="true"
            >
              Active
            </b-form-radio>
            <b-form-radio
              v-model="editInfo.isEnabled"
              name="some-radios"
              value="false"
            >
              Inactive
            </b-form-radio>
          </div>
        </b-form-group>
        <div class="ll-btn">
        <b-button
          variant="secondary"
          class="mr-1"
          @click="onClear1"
        >
          Clear
        </b-button>
        <b-button variant="info"
          @click="editSubmit"
          v-b-toggle.modifyS>
          Save
        </b-button>
      </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  // BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  VBToggle,
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BBadge,
  BFormRadio,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    // BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BFormRadio,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'statusType', label: this.$t('Status Type'), sortable: true },
        { key: 'statusNo', label: this.$t('Status No'), sortable: true },
        { key: 'statusDesc', label: this.$t('Status Desc'), sortable: true },
        { key: 'intCode', label: this.$t('Status Code'), sortable: true },
        {
          key: 'tag',
          label: this.$t('Tag'),
          sortable: true,
          class: 'tagBoxs',
        },
        { key: 'isEnabled', label: this.$t('Status'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      searchTerm: '',
      statusTypeList: [],
      statusList: [
        { value: null, status: 'all' },
        { value: true, status: 'active' },
        { value: false, status: 'inactive' },
      ],
      formTime: '',
      toTime: '',
      condition: {
        page: '1',
        size: '50',
        orderMole: 0,
        orderBy: 'id',
        statusNo: null,
        statusType: null,
        isEnabled: null,
      },
      statusNo: '',
      editInfo: {
        id: '',
        statusType: '',
        statusNo: '',
        statusDesc: '',
        tag: '',
        isEnabled: null,
      },
      color: '#409eff',
      color0: 'info',
      color1: 'primary',
      color2: 'danger',
      color3: 'secondary',
      isBusy: true,
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.getList()
    this.getStatusType()
  },
  methods: {
    // 首次查询
    getList() {
      this.$http.post('/statusManagement/search', this.condition).then(res => {
        // console.log('ss', res.data.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.rows = info.content
          this.page = info.page
          this.size = info.size
          this.totalSize = info.totalSize
          this.totalPage = info.totalPage
          this.isBusy = false
        }
      }).catch(error => console.log(error))
    },
    // 点击单行
    onRowClick() {
      // console.log('onRowClick')
    },
    onSearch() {
      // console.log('onSearch')
    },
    onClear() {
      this.condition = {}
    },
    // 条件过滤搜索
    filterSearch() {
      console.log(this.condition)
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    // 根据code查询
    searchById() {
      if (this.statusNo.trim() !== '') {
        this.condition.statusNo = this.statusNo.trim().split(/[(\r\n)\r\n]+/)
        this.getList()
      } else {
        this.condition = {}
        this.getList()
      }
    },
    // 获取status Type
    getStatusType() {
      this.$http.get('statusManagement/getStatusTypeList').then(res => {
        this.statusTypeList = res.data.data
      })
    },
    selStatusType(type) {
      this.condition.statusType = type.statusType.split()
    },
    selStatus(s) {
      this.condition.isEnabled = s.value
    },
    modify() {
    },
    // 分页
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    // 修改tag color
    headleChangeColor() {

    },
    // 点击编辑按钮
    onEdit(id, code) {
      this.editInfo.id = id
      this.condition.statusNo = code.split(/[(\r\n)\r\n]+/)
      this.$http.post('/statusManagement/search', this.condition).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data.content[0]
          this.editInfo.isEnabled = info.isEnabled
          this.editInfo.statusDesc = info.statusDesc
          this.editInfo.statusNo = info.statusNo
          this.editInfo.statusType = info.statusType
          this.editInfo.tag = info.tag
        }
      }).catch(error => console.log(error))
      // this.$http.get(`statusManagement/info/${code}`).then(res => {
      //   this.editInfo = res.data.data
      // })
    },
    onClear1() {
      this.editInfo = {}
    },
    // 编辑提交
    editSubmit() {
      this.$http.put('statusManagement/update', this.editInfo).then(res => {
        if (res.data.code === '200') {
          this.condition.statusNo = null
          this.getList()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edit Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          // this.getList()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: fixed;
  bottom: 40px;
  right: 10px;
} */
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-act{
  height: 15px;
  width: 15px;
}
.btn .feather{
  position: relative;
  top: -8px;
  left: -5px;
}
.ll-tagBox{
  display: flex;
  flex-wrap: wrap;
}
</style>
<style>
.tagBoxs{
  width: 10%;
}
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
.m-colorPicker .box.open[data-v-29accc04] {
  z-index: 9;
}
</style>
